<template>
  <div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item"><a href=""> Quản lý Tuyến xe </a></li>
    </ol>
    <div class="container-fluid">
      <div class="col-sm-10">
        <div class="form-group" style='display: none;'>
          <router-link to="/admin/tripassigment/add" class="btn btn-sm btn-primary">
            <i class="fas fa-plus"></i>
            Thêm mới Tuyến xe
          </router-link>
        </div>
      </div>

      <div>
        <form method="post" @submit="mySearch" id="my_search">
          <div class="row">
            <div class="col-sm-5 mb-3">
              <input
                type="text"
                name="name"
                class="form-control"
               
                autocomplete="off"
                placeholder="Nhập tên..."

                v-model="formSearch.name"
              />

              <button class="btn btn-sm btn-warning mt-3" type="submit">
                <i class="fa fa-search-plus"></i> TÌM KIẾM
              </button>
            </div>
             
          </div>
        </form>

      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header" v-if="is_loading">
              <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                <Skeleton width="40px" height="40"></Skeleton>
              </SkeletonTheme>
            </div>
            <div class="card-header" v-else>
              <i class="fa fa-align-justify" v-if="mydata != null"></i> Quản lý
              Tuyến xe : ( {{ page_size }} )
            </div>
            <div class="card-block">
              <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                <table class="table table-sm">
                  <thead>
                    <tr>
                       
                        <th>Tên DN</th>
                      <th>Biển số xe</th>
                      <th>Ngày ra bến  </th>
                      <th>Tuyến đường </th>
                      <th>Loại xe</th>
                       <th>Số ghế  </th>
                       <th> Quầy vé </th> 
                       <th>Giá vé </th> 
                       <th>Dừng bán vé </th> 
                       <th>Khoảng cách </th>
                      <th>Tuỳ chọn</th>

                    </tr>
                  </thead>
                  <tbody v-if="is_loading">
                    <tr v-for="(el, k) in 10" :key="k">
                      <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>
                       <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>
                       <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>

                      <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>
                      <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>
                      <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>
                      <td>
                        <a href="" target="_black" class="text text-primary">
                          <Skeleton></Skeleton>
                        </a>
                        <br />

                        <a href="" target="_black" class="text text-primary">
                          <Skeleton></Skeleton>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>




                    <tr
                      v-for="(value, key) in paginationslice(mydata, 10, page)"
                      :Key="key"
                    >
                       <td>
                        <strong>
                          {{value.tenDoanhNghiep}}
                          </strong>
                          <br />
                         <img :src="value.logo" style="width: 150px;">
                           <br />
                            Phone:   <strong>{{value.phone}} </strong>
                            <br />
                            Address: <strong>{{value.address}} </strong>
                      </td>
                      
                      <td >
                       {{ value.bienSoXe }} 
                      </td>
                      <td>
                          {{value.ngayRaBen}}
                      </td>
                     
                      <td>
                          {{value.tuyenDuong}}
                      </td>
                     

                      <td>
                          {{value.loaiXe}}
                      </td>
                     

                      <td>
                          {{value.soGhe}}
                      </td>

                       <td>
                          {{value.quayVe}}
                      </td>
                       <td>
                          {{value.giaVe}}
                      </td>
                       <td>
                          {{value.dungBanVe}}
                      </td>
                     

                       <td>
                          {{value.khoangCach}}
                      </td>
                       
                     

                      <td>
                        <div style='display: none;'>
                            <router-link
                              class="btn btn-sm btn-success"
                              v-bind:to="'/admin/tripassigment/edit/' + value.id"
                            >
                              <i class="fa fa-edit"></i>
                              Sửa
                            </router-link>
                            <div
                              class="btn btn-sm btn-danger click_remove"
                              @click="removeElement(value.id)"
                            >
                              <i class="fa fa-remove"></i> Xoá
                            </div>

                            </div>
                      </td>

                      
                    </tr>
                  </tbody>
                </table>

               <nav v-if="mydata != null && mydata.length > 10">
                  <VuePagination
                    :page-count="Math.ceil(mydata.length / 10)"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="changePage"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                  >
                  </VuePagination>
                </nav>
              </SkeletonTheme>
            </div>
          </div>
        </div>
        <!--/.col-->
      </div>
    </div>
  </div>
</template>

<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";
import VuePagination from "vuejs-paginate";
import axios from "axios";
export default {
  
  data() {
    return {
      is_loading: true,
      form: {},
      mydata: null,
      main:null,
      search: "",
      page: 1,
      id_load: "",
      mycate:null,
      my_url: window.my_url,

      page_size:0,
      is_remove:[],

      isLogin:null,
      formSearch:{
         name:"",
          cate:""
      },
      language: window.my_header.language,
      role:2
    };
  },
  components: {
    Skeleton,
    SkeletonTheme,
    VuePagination,
  },
  filters:{
    ViewCate:function(id_cate,mycate){
        var result= mycate.filter( (n)=> n.id==id_cate);
        if(result.length){
            return result[0].name;
        }
        return "";


    }

  },
  created(){  
      let isLogin= this.$session.get("isLogin");
      this.isLogin=isLogin.permistions.split(",");

      let Customer= this.$session.get("Customer");
      this.role=Customer.isRole;
  },
  mounted() {

   


   let p2=  axios
      .get(window.my_api + "api/tripassigment/get-tripassigments", {
        headers: window.my_header
      })
      .then((res) => {
        if (res.status == 200) {
          this.mydata  = res.data.data;
          this.page_size=this.mydata.length;
            

          
        } else {
          alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
        }
      });

      Promise.all([p2])
             .then( ()=>{
                this.is_loading = false;
             });
  },
  methods: {
     changePage(e) {
      this.page = e;
    },
    paginationslice(data, pagesize, page) {
      return data.filter((value, n) => {
        return n >= pagesize * (page - 1) && n < pagesize * page;
      });
    },

    mySearch(event) {
      event.preventDefault();
      


      var form =new FormData(document.getElementById("my_search") ) ;

      this.mydata=this.mydata.filter( (n)=>{
            
            if(form.get("name").length){
              return    n.tenDoanhNghiep.toUpperCase().search(form.get("name").toUpperCase() ) > -1;
            }
           
            return n;


      });

      return false;
     
    },

    removeAll(){
        
        if(this.is_remove.length > 0){
           for (var i = this.is_remove.length - 1; i >= 0; i--) {
            
              this.removeElement(this.is_remove[i]);
            }
        }
        return false;
         
    },
    removeElement(id) {
    if(confirm("Bạn muốn xoá?")){
            this.id_load = id;
            axios
              .delete(window.my_api + "api/tripassigment/remove-tripassigment", {
                data: { supplierIds: [id] },
                headers: window.my_header
              })
              
              .then((res) => {
                if (res.status == 200) {
                  alert("Xoá thành công");
                  this.mydata = this.mydata.filter((el) => el.id !== id);
                } else {
                  alert(
                    "Hệ thống không hoạt động, vui lòng liên hệ với Admin."
                  );
                }
                this.id_load = "";
              });
          }
        
    },
  },
};
</script>








